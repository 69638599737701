export default function formatTariff(tariffCode) {
  let tariffName;

  switch (tariffCode) {
    case "free_c":
      tariffName = "Клиент Фри";
      break;
    case "smart_c":
      tariffName = "Клиент Смарт";
      break;
    case "standard_c":
      tariffName = "Клиент Стандарт";
      break;
    case "business_d":
      tariffName = "Бизнес";
      break;
    case "premium_d":
      tariffName = "Премиум";
      break;
    case "vip_d":
      tariffName = "Франшиза";
      break;
    case "vip_gold_d":
      tariffName = "Франшиза Голд";
      break;
    default:
      tariffName = "Клиент Фри";
      break;
  }

  return tariffName;
}
