<script>
import formatQualification from "@/package/helpers/format-qualification.helpers";

export default {
  props: {
    qualificationCode: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedQualificationName() {
      return formatQualification(this.qualificationCode, true);
    },

    formattedQualificationImagePath() {
      if (this.qualificationCode) {
        return require(`../assets/qualifications/preview/${this.qualificationCode}.png`);
      } else {
        return require(`../assets/qualifications/default/without_qualification.png`);
      }
    },
  },
};
</script>

<template>
  <div class="qualification-preview">
    <img :src="formattedQualificationImagePath" alt="" />

    <p class="qualification-preview__name">{{ formattedQualificationName }}</p>
  </div>
</template>

<style lang="scss" scoped>
.qualification-preview {
  border-radius: $space-s;
  padding: $space-xs $space-s;
  overflow: hidden;
  width: fit-content;
  display: flex;
  align-items: center;
  position: relative;

  > img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: auto;
  }

  &__name {
    @include caption-1;
    color: $dark-primary;
    white-space: nowrap;
    z-index: 1;
  }
}
</style>
