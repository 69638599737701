<script>
import formatTariff from "@/package/helpers/format-tariff.helpers";

export default {
  props: {
    tariffCode: {
      type: String,
      required: true,
    },

    tariffIconColor: {
      type: String,
      default: "black",
    },
  },

  computed: {
    formattedTariffName() {
      return formatTariff(this.tariffCode);
    },

    showTariffIcon() {
      return (
        this.tariffCode === "vip_gold_d" ||
        this.tariffCode === "vip_d" ||
        this.tariffCode === "premium_d" ||
        this.tariffCode === "business_d"
      );
    },
  },
};
</script>

<template>
  <div class="tariff-preview">
    <span
      v-if="showTariffIcon"
      :class="[
        `tariff-preview__icon icon-${tariffCode}`,
        `tariff-preview__icon_color_${tariffIconColor}`,
      ]"
    />

    <p class="tariff-preview__name">{{ formattedTariffName }}</p>
  </div>
</template>

<style lang="scss" scoped>
.tariff-preview {
  background-color: $light-primary;
  border-radius: $space-s;
  padding: $space-xs $space-s;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;

  &__icon {
    width: 16px;
    height: 16px;

    &_color {
      &_black {
        background-color: $dark-primary;
      }

      &_white {
        background-color: $light-primary;
      }
    }
  }

  &__name {
    @include caption-1;
    color: $dark-primary;
    white-space: nowrap;
  }
}
</style>
