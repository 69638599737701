<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import vClickOutside from "v-click-outside";
import QualificationPreview from "@/components/QualificationPreview.vue";
import TariffPreview from "@/components/TariffPreview";
import IconBase from "@/components/helpers/IconBase";
const IconAccountPositionMaster = () =>
  import("@/components/icons/IconAccountPositionMaster");
const IconAccountPositionLeft = () =>
  import("@/components/icons/IconAccountPositionLeft");
const IconAccountPositionRight = () =>
  import("@/components/icons/IconAccountPositionRight");

export default {
  components: {
    IconAccountPositionRight,
    IconAccountPositionLeft,
    IconAccountPositionMaster,
    IconBase,
    QualificationPreview,
    TariffPreview,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      open: false,
    };
  },

  computed: {
    ...mapState({
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    ...mapGetters({
      getUserBinaryAccounts: "auth/getUserBinaryAccounts",
    }),

    filteredAccounts: function () {
      return this.getUserBinaryAccounts.filter(
        (account) => account.id !== this.selectedBinaryAccount.id
      );
    },
  },

  methods: {
    ...mapMutations({
      setBinaryAccount: "auth/SET_BINARY_ACCOUNT",
    }),

    selectAccount(item) {
      this.setBinaryAccount(item);
      this.open = false;
    },

    hideAccounts() {
      this.open = false;
    },

    formatQualificationCode(account) {
      return account?.qualification?.code ? account.qualification.code : "";
    },

    formatTariffCode(account) {
      return account?.tariff?.code ? account.tariff.code : "";
    },

    defineAccountPositionIcon(account) {
      return `icon-account-position-${account.position}`;
    },
  },
};
</script>

<template>
  <div
    v-click-outside="hideAccounts"
    class="account-selector"
    :class="{ 'account-selector_opened': open }"
  >
    <div
      class="account-selector__selected-item selected-item"
      @click="open = !open"
    >
      <div
        v-if="selectedBinaryAccount.position"
        class="selected-item__position"
      >
        <icon-base
          icon-name="icon-account-position"
          icon-fill="#212121"
          width="24"
          height="24"
        >
          <component :is="defineAccountPositionIcon(selectedBinaryAccount)" />
        </icon-base>
      </div>

      <div>
        <p class="selected-item__id">{{ selectedBinaryAccount.id }}</p>

        <div class="selected-item__info info">
          <qualification-preview
            :qualification-code="formatQualificationCode(selectedBinaryAccount)"
          />

          <tariff-preview
            :tariff-code="formatTariffCode(selectedBinaryAccount)"
          />
        </div>

        <div class="account-selector__arrow">
          <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L4 4L7 1" stroke="#212121" />
          </svg>
        </div>
      </div>
    </div>

    <ul
      class="account-selector__list"
      :class="{ 'account-selector__list_opened': open }"
    >
      <template v-if="filteredAccounts.length">
        <li
          v-for="account in filteredAccounts"
          :key="account.id"
          class="account-selector__item item"
          @click="selectAccount(account)"
        >
          <div v-if="account.position" class="item__position">
            <icon-base
              icon-name="icon-account-position"
              icon-fill="#ffffff"
              width="24"
              height="24"
            >
              <component :is="defineAccountPositionIcon(account)" />
            </icon-base>
          </div>

          <div>
            <p class="item__id">{{ account.id }}</p>

            <div class="item__info info">
              <qualification-preview
                :qualification-code="formatQualificationCode(account)"
              />

              <tariff-preview
                :tariff-code="formatTariffCode(account)"
                tariff-icon-color="white"
              />
            </div>
          </div>
        </li>
      </template>

      <template v-else>
        <li class="account-selector__item item item_without-accounts">
          <p>Нет доступных аккаунтов</p>
        </li>
      </template>
    </ul>
  </div>
</template>

<style lang="scss">
.account-selector {
  max-width: 404px;
  background-color: $background-white;
  border-radius: 8px 8px 0 0;
  padding: 24px 24px 16px 24px;
  position: relative;

  &__selected-item {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .selected-item {
    &__position {
      margin-right: $space-m;
    }

    &__info {
      display: flex;
    }

    .info {
      .qualification-preview {
        margin-right: $space-xs;
      }

      .tariff-preview {
        background-color: $light-second;
      }
    }

    &__id {
      @include text-2;
      color: $dark-fifth;
      margin-bottom: $space-xs;
      margin-right: $space-m;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    transition: transform 0.25s linear;
  }

  &__list {
    position: absolute;
    left: 0;
    z-index: 9999;
    background: $dark-primary;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px;
    width: 100%;
    padding: 0;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  &__list_opened {
    max-height: 400px;
    overflow: auto;
  }

  &__item {
    padding: $space-m $space-l;

    @media (max-width: 576px) {
      padding: $space-m 12px;
    }
  }

  .item {
    cursor: pointer;
    display: flex;
    align-items: center;

    &_without-accounts {
      p {
        @include text-2;
        color: $light-primary;
      }
    }

    &__position {
      margin-right: $space-m;
    }

    &__info {
      display: flex;

      .qualification-preview {
        margin-right: $space-xs;
      }

      .tariff-preview {
        background-color: $dark-second;

        &__name {
          color: $light-primary;
        }
      }
    }

    &__id {
      @include text-2;
      color: $light-fifth;
      margin-bottom: $space-xs;
    }
  }

  &_opened {
    .account-selector__arrow {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 576px) {
    padding: $space-l 12px $space-m;
  }

  @media (max-width: 425px) {
    .selected-item {
      &__id {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 220px;
      }
    }
  }
}
</style>
