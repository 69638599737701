<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },

    width: {
      type: [Number, String],
      default: 24,
    },

    height: {
      type: [Number, String],
      default: 24,
    },

    viewBoxWidth: {
      type: [Number, String],
      default: 24,
    },

    viewBoxHeight: {
      type: [Number, String],
      default: 24,
    },

    iconFill: {
      type: String,
      default: "",
    },

    iconStroke: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <svg
    class="icon-base"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${viewBoxWidth} ${viewBoxHeight}`"
    :aria-labelledby="iconName"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <g :fill="iconFill" :stroke="iconStroke">
      <slot />
    </g>
  </svg>
</template>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
